import React, { FC } from 'react';
import { Link } from 'gatsby';
import Carousel from 'nuka-carousel';

import Image from 'common/Image';

import { BannerTempraNinosProps } from './models';

const BannerTempraNinos: FC<BannerTempraNinosProps> = ({ bannerTempra: { banner } }) => {
  const { image, link } = banner[0].properties ? banner[0].properties : banner[0];
  const { url } = link[0];

  const bannerTempra = (
    <div className="slider__item">
      <Link to={url}>
        <Image imageData={image} alt={url} />
      </Link>
    </div>
  );

  return <Carousel withoutControls>{bannerTempra}</Carousel>;
};

export default BannerTempraNinos;
