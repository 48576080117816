import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ImgTextProps } from '../models';

const ImgTextLayout: FC<ImgTextProps> = ({ slide }) => {
  const { customClassName, title, description, image, imageMobile, link } = slide;
  const { url } = link || { url: '#' };
  const altTitle = title || '';
  const originalAltText = image
    ? image.childImageSharp.gatsbyImageData.images.fallback.src
    : altTitle;
  const imageName = originalAltText.split('/').pop();

  return (
    <Link to={url} className={`banner__type--text ${customClassName || ''} `}>
      {imageMobile && (
        <img
          src={imageMobile.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
          alt={imageName}
          className="banner__image banner__image--desktop"
        />
      )}
      {image && (
        <img
          src={image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
          alt={imageName}
          className="banner__image banner__image--mobile"
        />
      )}
      <div dangerouslySetInnerHTML={{ __html: description || '' }} />
    </Link>
  );
};

export default ImgTextLayout;
