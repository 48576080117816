import React, { FC } from 'react';
import { uniqueId } from 'lodash';
import Carousel from 'nuka-carousel';

import ImgButtonLayout from './Layouts/ImgButtonLayout';
import ImgTextButtonLayout from './Layouts/ImgTextButtonLayout';
import ImgTextLayout from './Layouts/ImgTextLayout';
import ImgUrlLayout from './Layouts/ImgUrlLayout';
import { ConditionalTextSliderProps } from './models';

import './styles/index.scss';

const ConditionalTextSlider: FC<ConditionalTextSliderProps> = ({ slider, className }) => {
  const renderSlider =
    slider !== undefined
      ? slider.map((slide: any) => {
          const { layout } = slide;
          switch (layout) {
            case 'ImgUrl':
              return <ImgUrlLayout key={uniqueId()} slide={slide} />;
            case 'ImgText':
              return <ImgTextLayout key={uniqueId()} slide={slide} />;
            case 'ImgButton':
              return <ImgButtonLayout key={uniqueId()} slide={slide} />;
            case 'ImgTextButton':
              return <ImgTextButtonLayout key={uniqueId()} slide={slide} />;
            default:
              return <ImgUrlLayout key={uniqueId()} slide={slide} />;
          }
        })
      : [];

  return (
    <div className={className}>
      <Carousel withoutControls={!(slider.length > 1)}>{renderSlider}</Carousel>
    </div>
  );
};

export default ConditionalTextSlider;
