import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ImgUrlProps } from '../models';

const ImgButtonLayout: FC<ImgUrlProps> = ({ slide }) => {
  const { customClassName, title, image, imageMobile, link, buttonText } = slide;
  const { url } = link || { url: '#' };
  const altTitle = title || '';
  const originalAltText = image
    ? image.childImageSharp.gatsbyImageData.images.fallback.src
    : altTitle;
  const imageName = originalAltText.split('/').pop();

  return (
    <div className={`banner__type--btn ${customClassName || ''} `}>
      {image && (
        <img
          src={image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
          alt={imageName}
          className="banner__image banner__image--desktop"
        />
      )}
      {imageMobile && (
        <img
          src={imageMobile.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
          alt={imageName}
          className="banner__image banner__image--mobile"
        />
      )}
      <Link to={url} className="banner__button">
        {buttonText || ''}
      </Link>
    </div>
  );
};

export default ImgButtonLayout;
