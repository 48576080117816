import React, { FC } from 'react';
import { Link } from 'gatsby';

import Image from 'common/Image';

import { ContentProps } from './models';

const Content: FC<ContentProps> = ({ node: { image, title }, url }) => (
  <div className="item__content">
    <div className="item__image">
      <Link to={url} className="item__link">
        <Image imageData={image} alt={url} />
      </Link>
    </div>
    <div className="item__title">
      <Link to={url} className="item__link">
        <p className="paragraph">{title}</p>
        <button aria-label="button" className="item__button" type="button" />
      </Link>
    </div>
  </div>
);

export default Content;
