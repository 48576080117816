import React, { FC } from 'react';
import { Link } from 'gatsby';

import { ImgTextProps } from '../models';

const ImgTextButtonLayout: FC<ImgTextProps> = ({ slide }) => {
  const { customClassName, title, description, image, imageMobile, link, buttonText } = slide;
  const { url } = link || { url: '#' };
  const altTitle = title || '';
  const originalAltText = image
    ? image.childImageSharp.gatsbyImageData.images.fallback.src
    : altTitle;
  const imageName = originalAltText.split('/').pop();

  return (
    <Link to={url} className="`bnn-btn-text__button">
      <div className={`bnn-btn-text ${customClassName || ''} `}>
        {image && (
          <img
            src={image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
            alt={imageName}
            className="banner__image banner__image--desktop"
          />
        )}
        {imageMobile && (
          <img
            src={imageMobile.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
            alt={imageName}
            className="banner__image banner__image--mobile"
          />
        )}
        <div className="`bnn-btn-text__tittle">{description || ''}</div>
        {buttonText || ''}
      </div>
    </Link>
  );
};

export default ImgTextButtonLayout;
