import React, { FC } from 'react';
import { graphql } from 'gatsby';

import InfoListComponent from 'layout/InfoListComponent';

import { InfoPageListProps } from './models';

const InfoPageList: FC<InfoPageListProps> = ({ data: { infoPageList } }) => (
  <InfoListComponent infoPage={infoPageList} />
);

export const query = graphql`
  query ($url: String) {
    infoPageList(url: { eq: $url }) {
      url
      title
      description
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      ccsExtraClass
      links {
        title
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        link {
          name
          url
          icon
        }
      }
      conditionalTextSlider {
        customClassName
        slider {
          customClassName
          description
          hasText
          image {
            childImageSharp {
              gatsbyImageData
            }
          }
          imageMobile {
            childImageSharp {
              gatsbyImageData
            }
          }
          layout
          link {
            url
          }
          title
        }
      }
    }
  }
`;

export default InfoPageList;
