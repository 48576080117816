import React, { FC } from 'react';

import { Layout } from 'layout';
import BannerTempraNinos from 'layout/BannerTempraNinos';
import ConditionalTextSlider from 'layout/ConditionalTextSlider';
import Content from 'layout/Content';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';

import { InfoListProps } from './models';

import './infoListComponent.scss';

const InfoListComponent: FC<InfoListProps> = ({
  infoPage: {
    conditionalTextSlider,
    banner,
    title,
    description,
    ccsExtraClass,
    links,
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
  },
}) => {
  const { slider, customClassName } =
    conditionalTextSlider && conditionalTextSlider.length > 0
      ? conditionalTextSlider[0]
      : { slider: [], customClassName: '' };

  const renderLinks = links.map((linksComponent) => {
    const { link, title: titleLinks } = linksComponent;
    const { url } = link[0];

    return url ? (
      <div className="content__column--auto col-auto content__column my-3" key={titleLinks}>
        <Content node={linksComponent} url={url} />
      </div>
    ) : null;
  });

  return (
    <Layout>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div className={`content container-fluid ${ccsExtraClass}`}>
        <div className="content__row row">
          <div className="content__column--12 col-12 px-0">
            <div className="content container">
              <div className="content__title">
                <h1 className="title">{title}</h1>
              </div>
              <div className="content__description">
                <p className="description">{description}</p>
              </div>
              <div className="content__row row justify-content-center text-center symptoms align-items-end">
                {renderLinks}
              </div>
            </div>
          </div>
          <div className="content__column--12 col-12 px-0">
            {slider.length > 0 && (
              <ConditionalTextSlider slider={slider} className={customClassName} />
            )}
            {banner ? <BannerTempraNinos bannerTempra={banner} /> : null}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default InfoListComponent;
